<template>
    <div id="formId" class="layout">
        <Table :columns="columns1" ref="myTable1" style="display:none;" :data="data1"></Table>
        <Table :columns="columns2" ref="myTable2" style="display:none;"  :data="data2"></Table>
        <Table :columns="columns3" ref="myTable3" style="display:none;"  :data="data3"></Table>
        <Layout>
            <Layout>
                <Header class="layout-header-bar">计算器</Header>
                <Content :style="{margin: '20px', background: '#fff', minHeight: '220px'}">
                    <div id="tabId">
                        <Tabs value="name1">
                            <TabPane label="方块" name="name1">
                                <Form ref="formInline" :model="formInline" label-position="left" :label-width="100">
                                    <FormItem label="长度" prop="l">
                                        <Input type="text" v-model="formInline.l" placeholder="l" class="form-input">
                                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                        </Input>
                                    </FormItem>
                                    <FormItem label="宽度" prop="w">
                                        <Input type="text" v-model="formInline.w" placeholder="w" class="form-input">
                                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                        </Input>
                                    </FormItem>
                                    <FormItem label="高度" prop="h">
                                        <Input type="text" v-model="formInline.h" placeholder="h" class="form-input">
                                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                        </Input>
                                    </FormItem>
                                    <FormItem label="切片适合高度" prop="h1">
                                        <Input type="text" v-model="formInline.h1" placeholder="h1" class="form-input">
                                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                        </Input>
                                    </FormItem>
                                    <FormItem label="切片系数" prop="a">
                                        <Input type="text" v-model="formInline.a" placeholder="a" class="form-input">
                                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                        </Input>
                                    </FormItem>
                                    <FormItem label="刀宽" prop="s">
                                        <Input type="text" v-model="formInline.s" placeholder="s" class="form-input">
                                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                        </Input>
                                    </FormItem>
                                    <FormItem label="价格" prop="b">
                                        <Input type="text" v-model="formInline.b" placeholder="b" class="form-input">
                                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                        </Input>
                                    </FormItem>
                                    <div class="btnClass">
                                        <Button type="primary" @click="handleSubmit('formInline')">计算</Button>
                                        <Button type="primary" @click="exportData('data1')" style="margin-left: 20px">导出</Button>
                                    </div>
                                    <div id="result">
                                        <Divider>计算结果</Divider>

                                        <Input type="text" v-model="formInline.f" placeholder="f" class="form-input">
                                            <span slot="prepend">总加工费:f</span>
                                        </Input>
                                        <Input type="text" v-model="formInline.fq" placeholder="s" class="form-input">
                                            <span slot="prepend">切片加工费:fq</span>
                                        </Input>
                                        <Input type="text" v-model="formInline.fd" placeholder="s" class="form-input">
                                            <span slot="prepend">多线加工费:fd</span>
                                        </Input>
                                        <Input type="text" v-model="formInline.fy" placeholder="fy" class="form-input">
                                            <span slot="prepend">成品每公斤值:fy</span>
                                        </Input>
                                        <Input type="text" v-model="formInline.roughcast" placeholder="roughcast"
                                               class="form-input">
                                            <span slot="prepend">毛坯每公斤值:roughcast</span>
                                        </Input>
                                        <!--<Input type="text" v-model="formInline.bl" placeholder="roughcast"
                                               class="form-input">
                                            <span slot="prepend">bl</span>
                                        </Input>
                                        <Input type="text" v-model="formInline.bh" placeholder="roughcast"
                                               class="form-input">
                                            <span slot="prepend">bh</span>
                                        </Input>
                                        <Input type="text" v-model="formInline.bw" placeholder="roughcast"
                                               class="form-input">
                                            <span slot="prepend">bw</span>
                                        </Input>-->
                                        <Input type="text" v-model="formInline.lilunchucailv" placeholder="s"
                                               class="form-input">
                                            <span slot="prepend">理论出材率</span>
                                        </Input>
                                    </div>

                                </Form>
                            </TabPane>
                            <TabPane label="圆片" name="name2">
                                <Form ref="disc" :model="disc" label-position="left" :label-width="100">
                                    <FormItem label="圆片直径" prop="l">
                                        <Input type="text" v-model="disc.d" placeholder="d" class="form-input">
                                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                        </Input>
                                    </FormItem>
                                    <FormItem label="圆片高度" prop="h">
                                        <Input type="text" v-model="disc.h" placeholder="h" class="form-input">
                                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                        </Input>
                                    </FormItem>
                                    <FormItem label="多线高度" prop="h1">
                                        <Input type="text" v-model="disc.h1" placeholder="h1" class="form-input">
                                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                        </Input>
                                    </FormItem>
                                    <FormItem label="切片系数" prop="a">
                                        <Input type="text" v-model="disc.a" placeholder="a" class="form-input">
                                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                        </Input>
                                    </FormItem>
                                    <FormItem label="多线几元/公斤" prop="b">
                                        <Input type="text" v-model="disc.b" placeholder="b" class="form-input">
                                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                        </Input>
                                    </FormItem>
                                    <Button type="primary" @click="handleSubmit('disc')">计算</Button>
                                    <Button type="primary" @click="exportData('data2')" style="margin-left: 20px">导出</Button>
                                    <div id="resultDisc">
                                        <Divider>计算结果</Divider>
                                        <Input type="text" v-model="disc.f" placeholder="s" class="form-input">
                                            <span slot="prepend">总加工费:f</span>
                                        </Input>
                                        <Input type="text" v-model="disc.fy" placeholder="fk" class="form-input">
                                            <span slot="prepend">成品每公斤值:fy</span>
                                        </Input>
                                        <Input type="text" v-model="disc.roughcast" placeholder="roughcast"
                                               class="form-input">
                                            <span slot="prepend">毛坯每公斤值:roughcast</span>
                                        </Input>
                                        <Input type="text" v-model="disc.lilunchucailv" placeholder="s"
                                               class="form-input">
                                            <span slot="prepend">理论出材率</span>
                                        </Input>
                                    </div>
                                </Form>
                            </TabPane>
                            <TabPane label="空心圆" name="name3">
                                <Form ref="circle" :model="circle" label-position="left" :label-width="100">
                                    <FormItem label="外园直径" prop="D">
                                        <Input type="text" v-model="circle.D" placeholder="D" class="form-input">
                                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                        </Input>
                                    </FormItem>
                                    <FormItem label="内园直径" prop="d">
                                        <Input type="text" v-model="circle.d" placeholder="d" class="form-input">
                                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                        </Input>
                                    </FormItem>
                                    <FormItem label="圆片高度" prop="h">
                                        <Input type="text" v-model="circle.h" placeholder="h" class="form-input">
                                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                        </Input>
                                    </FormItem>
                                    <FormItem label="多线高度" prop="h1">
                                        <Input type="text" v-model="circle.h1" placeholder="h1" class="form-input">
                                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                        </Input>
                                    </FormItem>
                                    <FormItem label="切片系数" prop="a">
                                        <Input type="text" v-model="circle.a" placeholder="a" class="form-input">
                                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                        </Input>
                                    </FormItem>
                                    <FormItem label="多线几元/公斤" prop="b">
                                        <Input type="text" v-model="circle.b" placeholder="b" class="form-input">
                                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                                        </Input>
                                    </FormItem>
                                    <Button type="primary" @click="handleSubmit('circle')">计算</Button>
                                    <Button type="primary" @click="exportData('data3')" style="margin-left: 20px">导出</Button>
                                    <div id="hollowCircle">
                                        <Divider>计算结果</Divider>
                                        <!--<Input type="text" v-model="circle.f" placeholder="f" style="width: 300px" >
                                            <span slot="prepend">总加工费:f</span>
                                        </Input>-->
                                        <Input type="text" v-model="circle.fk" placeholder="fk" class="form-input">
                                            <span slot="prepend">总加工费:fk</span>
                                        </Input>
                                        <Input type="text" v-model="circle.fy" placeholder="fk" class="form-input">
                                            <span slot="prepend">成品每公斤值:fy</span>
                                        </Input>
                                        <Input type="text" v-model="circle.roughcast" placeholder="roughcast"
                                               class="form-input">
                                            <span slot="prepend">毛坯每公斤值:roughcast</span>
                                        </Input>
                                        <Input type="text" v-model="circle.lilunchucailv" placeholder="s"
                                               class="form-input">
                                            <span slot="prepend">理论出材率</span>
                                        </Input>
                                    </div>
                                </Form>
                            </TabPane>
                        </Tabs>
                    </div>
                </Content>
            </Layout>
        </Layout>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isCollapsed: false,
                isShow:false,
                columns1: [
                    {
                        title: '总加工费',
                        key: 'f'
                    },
                    {
                        title: '切片加工费',
                        key: 'fq'
                    },
                    {
                        title: '多线加工费',
                        key: 'fd'
                    },
                    {
                        title: '成品每公斤值',
                        key: 'fy'
                    },
                    {
                        title: '毛坯每公斤值',
                        key: 'roughcast'
                    },
                    {
                        title: '理论出材率',
                        key: 'lilunchucailv'
                    }
                ],
                columns2: [
                    {
                        title: '总加工费',
                        key: 'f'
                    },
                    {
                        title: '成品每公斤值',
                        key: 'fy'
                    },
                    {
                        title: '毛坯每公斤值',
                        key: 'roughcast'
                    },
                    {
                        title: '理论出材率',
                        key: 'lilunchucailv'
                    }
                ],
                columns3: [
                    {
                        title: '总加工费',
                        key: 'fk'
                    },
                    {
                        title: '成品每公斤值',
                        key: 'fy'
                    },
                    {
                        title: '毛坯每公斤值',
                        key: 'roughcast'
                    },
                    {
                        title: '理论出材率',
                        key: 'lilunchucailv'
                    }
                ],
                data1: [
                    {
                        f: 0,
                        fq: 0,
                        fd: 0,
                        fy: 0,
                        roughcast: 0,
                        lilunchucailv: 0,
                    }
                ],
                data2: [
                    {
                        f: 0,
                        fy: 0,
                        roughcast: 0,
                        lilunchucailv: 0,
                    }
                ],
                data3: [
                    {
                        fk: 0,
                        fy: 0,
                        roughcast: 0,
                        lilunchucailv: 0,
                    }
                ],
                //算法
                formInline: {
                    x: 50,
                    n: 1,
                    s: 0.38,
                    l: 0,
                    w: 0,
                    h: 0,
                    h1: 10,
                    a: 0.0005,
                    f: 0,
                    fq: 0,
                    fd: 0,
                    fy: 0,
                    b: 4.5,
                    djm: 0,
                    lilunchucailv: 0,
                    roughcast: 0,
                    yie: 0,
                    bl: 0,
                    bh: 0,
                    bw: 0
                },
                disc: {
                    d: 0,
                    h: 0,
                    h1: 10,
                    a: 0.0005,
                    b: 5.85,
                    f: 0,
                    x: 50,
                    n: 1,
                    s: 0.38,
                    fy: 0,
                    roughcast: 0,
                    lilunchucailv: 0
                },
                circle: {
                    D: 0,
                    d: 0,
                    h: 0,
                    h1: 10,
                    a: 0.0005,
                    b: 5.85,
                    f: 0,
                    fk: 0,
                    x: 50,
                    n: 1,
                    s: 0.38,
                    fy: 0,
                    roughcast: 0,
                    lilunchucailv: 0
                },
            }
        },
        methods: {
            exportData(name) {
                if(name == "data1"){
                    this.data1 = [{
                        f: this.formInline.f,
                        fq: this.formInline.fq,
                        fd: this.formInline.fd,
                        fy: this.formInline.fy,
                        roughcast: this.formInline.roughcast,
                        lilunchucailv: this.formInline.lilunchucailv,
                    }]
                    this.$refs.myTable1.exportCsv({
                        filename: "方片",
                        original: false,
                        columns: this.columns1,
                        // orderid是订单编号，此处进行转换，因为orderid过长，不转换会变成科学计数法的格式
                        data: this.data1.map(k => {
                            k.orderid = '\t' + k.orderid;
                            return k;
                        })
                    });
                }else if(name == "data2"){
                    this.data2 = [{
                        f: this.disc.f,
                        fy: this.disc.fy,
                        roughcast: this.disc.roughcast,
                        lilunchucailv: this.disc.lilunchucailv,
                    }]
                    this.$refs.myTable2.exportCsv({
                        filename: "圆片",
                        original: false,
                        columns: this.columns2,
                        // orderid是订单编号，此处进行转换，因为orderid过长，不转换会变成科学计数法的格式
                        data: this.data2.map(k => {
                            k.orderid = '\t' + k.orderid;
                            return k;
                        })
                    });
                }else{
                    this.data3 = [{
                        fk: this.circle.fk,
                        fy: this.circle.fy,
                        roughcast: this.circle.roughcast,
                        lilunchucailv: this.circle.lilunchucailv,
                    }]
                    this.$refs.myTable3.exportCsv({
                        filename: "空心圆",
                        original: false,
                        columns: this.columns3,
                        // orderid是订单编号，此处进行转换，因为orderid过长，不转换会变成科学计数法的格式
                        data: this.data3.map(k => {
                            k.orderid = '\t' + k.orderid;
                            return k;
                        })
                    });
                }

            },
            handleSubmit(name) {
                if (name == "formInline") {
                    this.formInline.yie = this.yield(this.formInline.x, this.formInline.n, this.formInline.s);
                    this.$refs[name].validate((valid) => {
                        if (valid) {
                            this.$Message.success('Success!');
                            this.sliceProcessingFee(this.formInline.l, this.formInline.w, this.formInline.h, this.formInline.h1, this.formInline.a, this.formInline.x, this.formInline.n, this.formInline.s);
                        } else {
                            this.$Message.error('Fail!');
                        }
                    })
                } else if (name == "disc") {
                    this.$refs[name].validate((valid) => {
                        if (valid) {
                            this.$Message.success('Success!');
                            this.yuan(this.disc.d, this.disc.h, this.disc.h1, this.disc.b, this.disc.a, this.disc.n);
                        } else {
                            this.$Message.error('Fail!');
                        }
                    })
                } else if (name == "circle") {
                    this.$refs[name].validate((valid) => {
                        if (valid) {
                            this.$Message.success('Success!');
                            this.hollowCircle(this.circle.D, this.circle.d, this.circle.h, this.circle.h1, this.circle.b, this.circle.a, this.circle.n);
                        } else {
                            this.$Message.error('Fail!');
                        }
                    })
                }


            },
            //一刀出材率
            yield(x, n, s) {
                return this.nor(x, n, s, 45, 30, 1.5)
                // if(x > 30){
                //     return this.accDiv(x,this.accAdd(x,1.5));
                // }
                // let v = this.accAdd(this.accMul(this.accAdd(x,s),n),1.5);
                // if (x <= 30 && v < 50) {
                //     return this.yield(x,n+1,s);
                // }
                // return this.accMul(x ,n) / (this.accAdd(x,s) * n + 1.5);
            },
            //圆片出材率
            yuanYield(x, n, s) {
                return this.nor(x, n, s, 35, 20, 1.5)
                // if(x > 20){
                //     return this.accDiv(x,this.accAdd(x,1.5));
                // }
                // let v = this.accAdd(this.accMul(this.accAdd(x,s),n),1.5);
                // if (x <= 20 && v < 35) {
                //     return this.yuanYield(x,n+1,s);
                // }
                // return this.accMul(x ,n) / (this.accAdd(x,s) * n + 1.5);
            },
            //默认出材率
            nor(x, n, s, max, min, other) {
                if (x > min) {
                    return this.accDiv(x, this.accAdd(x, other));
                }
                let v = this.accAdd(this.accMul(this.accAdd(x, s), n), other);
                if (x <= min && v < max) {
                    return this.nor(x, n + 1, s, max, min, other);
                }
                return this.accMul(x, n) / (this.accAdd(x, s) * n + other);
            },
            //切片加工费
            sliceProcessingFee(l, w, h, h1, a, x, n, s) {
                let t1 = 0;
                let t2 = 0;
                let t3 = 0;
                let f1 = 0;
                let f2 = 0;
                let f3 = 0;
                let djm = 1;
                let theory = 1;

                //t1,2,3=0的时候就是不切
                if (Number(l) > Number(h1) && 30 > Number(l)) {
                    t1 = 1.0;
                    theory = theory * this.yield(l, n, s);
                } else {
                    let arg2 = this.yield(l, n, this.knifeWidth(l));
                    djm = this.accMul(djm, arg2);
                    console.log("t1要多线==》" + djm);
                    theory = this.accMul(theory, arg2);
                }
                if (30 > Number(w) && Number(w) > Number(h1)) {
                    t2 = 1.0;
                    theory = theory * this.yield(w, n, s);
                } else {
                    djm = this.accMul(djm, this.yield(w, n, this.knifeWidth(w)));
                    console.log("t2要多线==》" + djm);
                    theory = this.accMul(theory, this.yield(w, n, this.knifeWidth(w)));
                }
                if (25 > Number(h) && Number(h) > Number(h1)) {
                    t3 = 1.0;
                    theory = theory * this.yield(h, n, s);
                } else {
                    djm = this.accMul(djm, this.yield(h, n, this.knifeWidth(h)));
                    console.log("t3要多线==》" + djm);
                    theory = this.accMul(theory, this.yield(h, n, this.knifeWidth(h)));
                }

                let f1_j = this.yield(l, n, s);
                let f2_j = this.yield(w, n, s);
                let f3_j = this.yield(h, n, s);
                f1 = w * h / f1_j * t1 * a;
                f2 = h * l / f2_j * t2 * a;
                f3 = w * l / f3_j * t3 * a;
                let fq = f1 + f2 + f3;
                this.formInline.fq = fq;
                // let t = 3 - t1 - t2 - t3;
                //理论出材率


                let bL = 0;
                let bw = 0;
                let bh = 0;

                //修改部分
                // 如果 h1>L>=8 ,  则 bL=b*0.8
                // 如果 8>L>=5 ,  则 bL=b
                // 如果 5>L>=4 ,  则 bL=b*1.05
                // 如果 4>L>=3 ,  则 bL=b*1.1
                // 如果 3>L>=2 ,  则 bL=b*1.3
                // 如果 2>L>=1 ,  则 bL=b*2
                // 如果 1>L>=0.7 ,  则 bL=b*2.5
                // 如果 0.7>L>=0.5 ,  则 bL=b*3.66
                // 如果  L<0.5 , 则 bL=b*4

                if (Number(h1) > Number(l) && Number(l) >- 8) {
                    bL = this.formInline.b * 0.8;
                } else if (8 > Number(l) && Number(l) >= 5) {
                    bL = this.formInline.b;
                } else if (5 > Number(l) && Number(l) >= 4) {
                    bL = this.formInline.b * 1.05;
                } else if (4 > Number(l) && Number(l) >= 3) {
                    bL = this.formInline.b * 1.1;
                } else if (3 > Number(l) && Number(l) >= 2) {
                    bL = this.formInline.b * 1.3;
                } else if (2 > Number(l) && Number(l) >= 1) {
                    bL = this.formInline.b * 2;
                } else if (1 > Number(l) && Number(l) >= 0.7) {
                    bL = this.formInline.b * 2.5;
                } else if (0.7 > Number(l) && Number(l) >= 0.5) {
                    bL = this.formInline.b * 3.66;
                } else if (Number(l) < 0.5) {
                    bL = this.formInline.b * 4;
                }
                this.formInline.bl = bL;

                // 如果 h1>w>=8 ,  则 bw=b*0.8
                // 如果 8>w>=5 ,  则 bw=b
                // 如果 5>w>=4 ,  则 bw=b*1.05
                // 如果 4>w>=3 ,  则 bw=b*1.1
                // 如果 3>w>=2 ,  则 bw=b*1.3
                // 如果 2>w>=1 ,  则 bw=b*2
                // 如果 1>w>=0.7 ,  则 bw=b*2.5
                // 如果 0.7>w>=0.5 ,  则 bw=b*3.66
                // 如果  w<0.5 , 则 bw=b*4

                if (Number(h1) > Number(w) && Number(w) >= 8) {
                    bw = this.formInline.b * 0.8;
                } else if (8 > Number(w) && Number(w) >= 5) {
                    bw = this.formInline.b;
                } else if (5 > Number(w) && Number(w) >= 4) {
                    bw = this.formInline.b * 1.05;
                } else if (4 > Number(w) && Number(w) >= 3) {
                    bw = this.formInline.b * 1.1;
                } else if (3 > Number(w) && Number(w) >= 2) {
                    bw = this.formInline.b * 1.3;
                } else if (2 > Number(w) && Number(w) >= 1) {
                    bw = this.formInline.b * 2;
                } else if (1 > Number(w) && Number(w) >= 0.7) {
                    bw = this.formInline.b * 2.5;
                } else if (0.7 > Number(w) && Number(w) >= 0.5) {
                    bw = this.formInline.b * 3.66;
                } else if (Number(w) < 1) {
                    bw = this.formInline.b * 4;
                }
                this.formInline.bw = bw;

                // 如果 h1>h>=8 ,  则 bh=b*0.8
                // 如果 8>h>=5 ,  则 bh=b
                // 如果 5>h>=4 ,  则 bh=b*1.05
                // 如果 4>h>=3 ,  则 bh=b*1.1
                // 如果 3>h>=2 ,  则 bh=b*1.3
                // 如果 2>h>=1 ,  则 bh=b*2
                // 如果 1>h>=0.7 ,  则 bh=b*2.5
                // 如果 0.7>h>=0.5 ,  则 bh=b*3.66
                // 如果  h<0.5 , 则 bh=b*4
                if (Number(h1) > Number(h) && Number(h) >= 8) {
                    bh = this.formInline.b * 0.8;
                } else if (8 > Number(h) && Number(h) >= 5) {
                    bh = this.formInline.b;
                } else if (5 > Number(h) && Number(h) >= 4) {
                    bh = this.formInline.b * 1.05;
                } else if (4 > Number(h) && Number(h) >= 3) {
                    bh = this.formInline.b * 1.1;
                } else if (3 > Number(h) && Number(h) >= 2) {
                    bh = this.formInline.b * 1.3;
                } else if (2 > Number(h) && Number(h) >= 1) {
                    bh = this.formInline.b * 2;
                } else if (1 > Number(h) && Number(h) >= 0.7) {
                    bh = this.formInline.b * 2.5;
                } else if (0.7 > Number(h) && Number(h) >= 0.5) {
                    bh = this.formInline.b * 3.66;
                } else if (Number(h) < 0.5) {
                    bh = this.formInline.b * 4;
                }
                this.formInline.bh = bh;


                //多线出材率
                let accDiv1 = this.accDiv(parseFloat(l) * parseFloat(w) * parseFloat(h) * 7.55, 1000000);
                let fd = this.accAdd(this.accAdd(bL,bw), bh) / djm * (accDiv1);

                //计算多线出材率
                this.formInline.djm = djm;
                // let fd = t * this.formInline.b / djm * (l * w * h * 7.55 / 1000000);
                this.formInline.fd = fd;
                //总加工费
                let f = fd + fq;
                // 每公斤值
                let fy = f / (l * w * h * 7.55 / 1000000);
                this.formInline.f = f;
                this.formInline.fy = fy;
                this.formInline.roughcast = this.accMul(this.formInline.fy, theory)
                this.formInline.lilunchucailv = theory;
            },
            //圆片
            yuan(d, h, h1, b, a, n) {
                // 改成:
                //
                // 如果 h>=8 ,  则 b=b*0.8
                // 如果 8>h>=5 ,  则 b=b
                // 如果 5>h>=4 ,  则 b=b*1.05
                // 如果 4>h>=3 ,  则 b=b*1.1
                // 如果 3>h>=2 ,  则 b=b*1.3
                // 如果 2>h>=1 ,  则 b=b*2
                // 如果 1>h>=0.7 ,  则 b=b*2.5
                // 如果 0.7>h>=0.5 ,  则 b=b*3.66
                // 如果  h<0.5 , 则 b=b*4
                if(Number(h) >= 8){
                    b = b * 0.8;
                }else if (8 > Number(h) && Number(h) >= 5) {
                    b = b * 1;
                } else if (5 > Number(h) && Number(h) >= 4) {
                    b = b * 1.05;
                } else if (4 > Number(h) && Number(h) >= 3) {
                    b = b * 1.1;
                } else if (3 > Number(h) && Number(h) >= 2) {
                    b = b * 1.3;
                } else if (2 > Number(h) && Number(h) >= 1) {
                    b = b * 2;
                } else if (1 > Number(h) && Number(h) >= 0.7) {
                    b = b * 2.5;
                } else if (0.7 > Number(h) && Number(h) >= 0.5) {
                    b = b * 3.66;
                } else if (Number(h) < 0.5) {
                    b = b * 4;
                }

                let j = this.yuanYield(h, n, this.knifeWidth(h));
                let f = 1;
                if (Number(h) > Number(h1)) {
                    f = Math.pow(d, 2) * 3.14 / 4 * a;
                } else {
                    f = Math.pow(d, 2) * 3.14 / 4 * parseFloat(h) * 7.55 * b / (Math.pow(10, 6) * j);
                }
                this.disc.f = f;
                let fy = f / (Math.pow(d, 2) * 3.14 / 4 * parseFloat(h) * 7.55 / Math.pow(10, 6));
                this.disc.fy = fy;
                this.disc.roughcast = fy * j;
                this.disc.lilunchucailv = j;
            },
            //空心圆
            hollowCircle(D, d, h, h1, b, a, n) {
                // 改成:
                //
                // 如果 h>=8 ,  则 b=b*0.8
                // 如果 8>h>=5 ,  则 b=b
                // 如果 5>h>=4 ,  则 b=b*1.05
                // 如果 4>h>=3 ,  则 b=b*1.1
                // 如果 3>h>=2 ,  则 b=b*1.3
                // 如果 2>h>=1 ,  则 b=b*2
                // 如果 1>h>=0.7 ,  则 b=b*2.5
                // 如果 0.7>h>=0.5 ,  则 b=b*3.66
                // 如果  h<0.5 , 则 b=b*4
                if(Number(h) >= 8){
                    b = b * 0.8;
                }else if (8 > Number(h) && Number(h) >= 5) {
                    b = b * 1;
                } else if (5 > Number(h) && Number(h) >= 4) {
                    b = b * 1.05;
                } else if (4 > Number(h) && Number(h) >= 3) {
                    b = b * 1.1;
                } else if (3 > Number(h) && Number(h) >= 2) {
                    b = b * 1.3;
                } else if (2 > Number(h) && Number(h) >= 1) {
                    b = b * 2;
                } else if (1 > Number(h) && Number(h) >= 0.7) {
                    b = b * 2.5;
                } else if (0.7 > Number(h) && Number(h) >= 0.5) {
                    b = b * 3.66;
                } else if (Number(h) < 0.5) {
                    b = b * 4;
                }

                let j = this.yuanYield(h, n, this.knifeWidth(h));
                let f = 1;
                if (Number(h) > Number(h1)) {
                    f = Math.pow(D, 2) * 3.14 / 4 * a;
                } else {
                    f = Math.pow(D, 2) * 3.14 / 4 * h * 7.55 * b / (Math.pow(10, 6) * j);
                }
                this.circle.f = f;
                let fk = f / ((Math.pow(D, 2) - Math.pow(d, 2)) / Math.pow(D, 2));
                this.circle.fk = fk;
                let fy = fk / (Math.pow(D, 2) * 3.14 / 4 * h * 7.55 / 1000000);
                this.circle.fy = fy;
                this.circle.roughcast = fy * j;
                this.circle.lilunchucailv = j;
            },
            /**
             * 算出刀宽
             * @param {Object} lineWidth
             */
            knifeWidth(lineWidth) {
                if (lineWidth > 0 && lineWidth < 1) {
                    return 0.19;
                } else if (lineWidth >= 1 && lineWidth < 3) {
                    return 0.23;
                } else if (lineWidth >= 3 && lineWidth < 5) {
                    return 0.26;
                } else if (lineWidth >= 5 && lineWidth <= 10) {
                    return 0.29;
                }
                return 0.38;
            },
            /**
             * 加法函数，用来得到精确的加法结果
             * @param {Object} arg1
             * @param {Object} arg2
             */
            accAdd(arg1, arg2) {
                var r1 = this.deal(arg1);
                var r2 = this.deal(arg2);
                var m = Math.pow(10, Math.max(r1, r2))
                return (arg1 * m + arg2 * m) / m
            },

            /**
             * 乘法函数，用来得到精确的乘法结果
             * @param {Object} arg1
             * @param {Object} arg2
             */
            accMul(arg1, arg2) {
                var m = 0;
                m += this.deal(arg1);
                m += this.deal(arg2);
                var r1 = Number(arg1.toString().replace(".", ""));
                var r2 = Number(arg2.toString().replace(".", ""));
                return (r1 * r2) / Math.pow(10, m)
            },

            /**
             * 除法函数，用来得到精确的除法结果
             * @param {Object} arg1
             * @param {Object} arg2
             */
            accDiv(arg1, arg2) {
                var t1 = this.deal(arg1);
                var t2 = this.deal(arg2);
                var r1 = Number(arg1.toString().replace(".", ""))
                var r2 = Number(arg2.toString().replace(".", ""))
                return (r1 / r2) * Math.pow(10, t2 - t1);
            },
            /**
             * 求小数点后的数据长度
             */
            deal(arg) {
                var t = 0;
                try {
                    t = arg.toString().split(".")[1].length
                } catch (e) {
                    // console.log("");
                }
                return t;
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }
    @media screen  and (min-width: 400px) {
        #formId {
            margin: 0 auto;
            width: 80%;
        }

        #tabId {
            width: 500px;
            margin: 0 auto;
        }

        .form-input {
            width: 300px;
        }
    }

    @media screen  and (max-width: 400px) {
        #formId {
            margin: 0 auto;
            width: 100%;
        }
        .ivu-form-item{
            margin-bottom: 10px;
        }
    }

    #result, #resultDisc, #hollowCircle {
        margin-bottom: 20px;
    }

    .layout {
        border: 1px solid #d7dde4;
        background: #f5f7f9;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
    }

    .layout-header-bar {
        background: #fff;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
    }

    .menu-item span {
        display: inline-block;
        overflow: hidden;
        width: 69px;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: bottom;
        transition: width .2s ease .2s;
    }

    .menu-item i {
        transform: translateX(0px);
        transition: font-size .2s ease, transform .2s ease;
        vertical-align: middle;
        font-size: 16px;
    }

    .collapsed-menu span {
        width: 0px;
        transition: width .2s ease;
    }

    .collapsed-menu i {
        transform: translateX(5px);
        transition: font-size .2s ease .2s, transform .2s ease .2s;
        vertical-align: middle;
        font-size: 22px;
    }
</style>
