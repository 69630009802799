//该文件专门用于创建整个应用的路由器
import VueRouter from "vue-router";
//引入组件
import yuanpian from "@/components/yuanpian";
new VueRouter({
    routes:[
        {
            path:'/yuanpian',
            component:yuanpian
        }
    ]
});
